import React from "react"
import { Link } from "gatsby"

import footerLogo from "../images/favicon.png"

const Footer = () => (
  <footer>
    <div id="footer" className="footer-container">
      <div className="column">
        <Link aria-current="page" className="footer__logo" to="/">
          <img loading="lazy" src={footerLogo} alt="logo" />
        </Link>
        <div className="footer__copyright">© {new Date().getFullYear()} Framehub</div>
      </div>
      <div className="column">
        <ul>
          {/* <li>
            <a href="#">About Us</a>
          </li>
          <li>
            <a href="#">Projects</a>
          </li>
          <li>
            <a href="#">Jobs</a>
          </li>
          <li>
            <a href="#">Directors</a>
          </li> */}
          <li>
            <a href="https://framehub.de/" target="_blank">Media Production</a>
          </li>
          <li>
            <Link to="/imprint">Imprint</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </div>
      <div className="column">
        <a
          href="https://www.google.com/maps/place/Obernstra%C3%9Fe+50,+33602+Bielefeld,+Germany/@52.01955,8.5251065,17z/data=!3m1!4b1!4m5!3m4!1s0x47ba3d19eaa9db55:0x4aecbc2e61be95a7!8m2!3d52.01955!4d8.5272952"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Obernstraße 50</p>
          <p>33602 Bielefeld</p>
        </a>
        <p>+49 176 24924446</p>
      </div>
      {/* <div className="column">
        <a
          href="https://goo.gl/maps/qo73jNZJVLo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Office Hamburg</p>
          <p>Eifflerstraße 43</p>
          <p>22769 Hamburg</p>
        </a>
        <p>0049 40 66876457</p>
      </div> */}
      <div className="column">
        <ul>
          <li>
            <a href="mailto:team@framehub.io">team@framehub.io</a>
          </li>
          {/* <li>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/framehub.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          {/* <li>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vimeo
            </a>
          </li> */}
          <li>
            <a
              href="https://www.linkedin.com/company/framehub/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
