import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Scrollchor from "react-scrollchor"

// import logoFile from "../images/favicon.png"


class Header extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      hamburgerMenuOpen: false
    }
  }

  toggleHamburgerMenu = () => {
    this.setState({
      hamburgerMenuOpen: !this.state.hamburgerMenuOpen
    });
  }

  render() {

    let mobileHamburgerStyle = this.state.hamburgerMenuOpen ? { opacity: 1 } : { opacity: 0, display: 'none' };
    let mobileLinkDisplay = this.state.hamburgerMenuOpen ? "hambueger-link-show": "hambueger-link";

    return (
      <header>
        <nav className="custom-navbar" role="navigation" aria-label="main-navigation">
          <div className="navbar__wrapper">
            <div className="navbar__logo">
              {/* <Link title="Logo" className="" to="/">
                <img loading="lazy" src={logoFile} alt="logo" />
              </Link> */}
            </div>
            <ul className="navbar__desktop">
              {/* <li><a className="menu-link" href="#">About</a></li>
              <li><a className="menu-link" href="#">Projects</a></li>
              <li><a className="menu-link" href="#">Directors</a></li>
              <li><a className="menu-link" href="#">Jobs</a></li> */}
              <li>
                <Scrollchor to="footer">
                  <Link className="menu-link" to="/#footer">Contact</Link>
                </Scrollchor>
              </li>
              <li>
                <a href="mailto:team@framehub.io">
                  <img loading="lazy" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1tYWlsIj48cGF0aCBkPSJNNCA0aDE2YzEuMSAwIDIgLjkgMiAydjEyYzAgMS4xLS45IDItMiAySDRjLTEuMSAwLTItLjktMi0yVjZjMC0xLjEuOS0yIDItMnoiPjwvcGF0aD48cG9seWxpbmUgcG9pbnRzPSIyMiw2IDEyLDEzIDIsNiI+PC9wb2x5bGluZT48L3N2Zz4=" alt="mail" />
                </a>
              </li>
              <li style={{ marginRight: '0px' }}>
                <a href="tel:+49 176 24924446">
                  <img loading="lazy" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1waG9uZSI+PHBhdGggZD0iTTIyIDE2LjkydjNhMiAyIDAgMCAxLTIuMTggMiAxOS43OSAxOS43OSAwIDAgMS04LjYzLTMuMDcgMTkuNSAxOS41IDAgMCAxLTYtNiAxOS43OSAxOS43OSAwIDAgMS0zLjA3LTguNjdBMiAyIDAgMCAxIDQuMTEgMmgzYTIgMiAwIDAgMSAyIDEuNzIgMTIuODQgMTIuODQgMCAwIDAgLjcgMi44MSAyIDIgMCAwIDEtLjQ1IDIuMTFMOC4wOSA5LjkxYTE2IDE2IDAgMCAwIDYgNmwxLjI3LTEuMjdhMiAyIDAgMCAxIDIuMTEtLjQ1IDEyLjg0IDEyLjg0IDAgMCAwIDIuODEuN0EyIDIgMCAwIDEgMjIgMTYuOTJ6Ij48L3BhdGg+PC9zdmc+" alt="phone"/>
                </a>
              </li>
            </ul>
            <ul className="navbar__mobile">
              <li style={{ marginRight: '0px' }}>
                <a href="mailto:team@framehub.io">
                  <img loading="lazy" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1tYWlsIj48cGF0aCBkPSJNNCA0aDE2YzEuMSAwIDIgLjkgMiAydjEyYzAgMS4xLS45IDItMiAySDRjLTEuMSAwLTItLjktMi0yVjZjMC0xLjEuOS0yIDItMnoiPjwvcGF0aD48cG9seWxpbmUgcG9pbnRzPSIyMiw2IDEyLDEzIDIsNiI+PC9wb2x5bGluZT48L3N2Zz4=" alt="mail" />
                </a>
              </li>
              <li style={{ marginRight: '0px' }}>
                <a href="tel:+49 176 24924446">
                  <img loading="lazy" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1waG9uZSI+PHBhdGggZD0iTTIyIDE2LjkydjNhMiAyIDAgMCAxLTIuMTggMiAxOS43OSAxOS43OSAwIDAgMS04LjYzLTMuMDcgMTkuNSAxOS41IDAgMCAxLTYtNiAxOS43OSAxOS43OSAwIDAgMS0zLjA3LTguNjdBMiAyIDAgMCAxIDQuMTEgMmgzYTIgMiAwIDAgMSAyIDEuNzIgMTIuODQgMTIuODQgMCAwIDAgLjcgMi44MSAyIDIgMCAwIDEtLjQ1IDIuMTFMOC4wOSA5LjkxYTE2IDE2IDAgMCAwIDYgNmwxLjI3LTEuMjdhMiAyIDAgMCAxIDIuMTEtLjQ1IDEyLjg0IDEyLjg0IDAgMCAwIDIuODEuN0EyIDIgMCAwIDEgMjIgMTYuOTJ6Ij48L3BhdGg+PC9zdmc+" alt="phone"/>
                </a>
              </li>
              <li className={this.state.hamburgerMenuOpen ? "burgericon is-clicked": "burgericon "} onClick={this.toggleHamburgerMenu}><span></span></li>
            </ul>
            <ul className="navbar__burgermenu " style={mobileHamburgerStyle}>
              <div>
                {/* <li className={mobileLinkDisplay}>
                  <a className="menu-link" href="#">About</a>
                </li>
                <li className={mobileLinkDisplay}>
                  <a className="menu-link" href="#">Projects</a>
                </li>
                <li className={mobileLinkDisplay}>
                  <a className="menu-link" href="#">Directors</a>
                </li>
                <li className={mobileLinkDisplay}>
                  <a className="menu-link" href="#">Jobs</a>
                </li> */}
                <li className={mobileLinkDisplay}>
                  <Scrollchor to="footer">
                    <Link onClick={this.toggleHamburgerMenu} className="menu-link" to="/#footer">Contact</Link>
                  </Scrollchor>
                </li>
              </div>
            </ul>
          </div>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
